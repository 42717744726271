/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ApiProxyTrafficSpecV1Dto } from './ApiProxyTrafficSpecV1Dto';
import {
    instanceOfApiProxyTrafficSpecV1Dto,
    ApiProxyTrafficSpecV1DtoFromJSON,
    ApiProxyTrafficSpecV1DtoFromJSONTyped,
    ApiProxyTrafficSpecV1DtoToJSON,
} from './ApiProxyTrafficSpecV1Dto';
import type { GenericProxyTrafficSpecV1Dto } from './GenericProxyTrafficSpecV1Dto';
import {
    instanceOfGenericProxyTrafficSpecV1Dto,
    GenericProxyTrafficSpecV1DtoFromJSON,
    GenericProxyTrafficSpecV1DtoFromJSONTyped,
    GenericProxyTrafficSpecV1DtoToJSON,
} from './GenericProxyTrafficSpecV1Dto';
import type { NoTrafficSpecV1Dto } from './NoTrafficSpecV1Dto';
import {
    instanceOfNoTrafficSpecV1Dto,
    NoTrafficSpecV1DtoFromJSON,
    NoTrafficSpecV1DtoFromJSONTyped,
    NoTrafficSpecV1DtoToJSON,
} from './NoTrafficSpecV1Dto';

/**
 * @type ExternalTrafficSpecV1Dto
 * Configuration of external traffic.
 * @export
 */
export type ExternalTrafficSpecV1Dto = { externalTrafficType: 'API_PROXY' } & ApiProxyTrafficSpecV1Dto | { externalTrafficType: 'GENERIC_PROXY' } & GenericProxyTrafficSpecV1Dto | { externalTrafficType: 'NO_TRAFFIC' } & NoTrafficSpecV1Dto;

export function ExternalTrafficSpecV1DtoFromJSON(json: any): ExternalTrafficSpecV1Dto {
    return ExternalTrafficSpecV1DtoFromJSONTyped(json, false);
}

export function ExternalTrafficSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalTrafficSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['externalTrafficType']) {
        case 'API_PROXY':
            return {...ApiProxyTrafficSpecV1DtoFromJSONTyped(json, true), externalTrafficType: 'API_PROXY'};
        case 'GENERIC_PROXY':
            return {...GenericProxyTrafficSpecV1DtoFromJSONTyped(json, true), externalTrafficType: 'GENERIC_PROXY'};
        case 'NO_TRAFFIC':
            return {...NoTrafficSpecV1DtoFromJSONTyped(json, true), externalTrafficType: 'NO_TRAFFIC'};
        default:
            throw new Error(`No variant of ExternalTrafficSpecV1Dto exists with 'externalTrafficType=${json['externalTrafficType']}'`);
    }
}

export function ExternalTrafficSpecV1DtoToJSON(value?: ExternalTrafficSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['externalTrafficType']) {
        case 'API_PROXY':
            return ApiProxyTrafficSpecV1DtoToJSON(value);
        case 'GENERIC_PROXY':
            return GenericProxyTrafficSpecV1DtoToJSON(value);
        case 'NO_TRAFFIC':
            return NoTrafficSpecV1DtoToJSON(value);
        default:
            throw new Error(`No variant of ExternalTrafficSpecV1Dto exists with 'externalTrafficType=${value['externalTrafficType']}'`);
    }

}

