/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivityLogSpecV1SourceDto } from './ActivityLogSpecV1SourceDto';
import {
    ActivityLogSpecV1SourceDtoFromJSON,
    ActivityLogSpecV1SourceDtoFromJSONTyped,
    ActivityLogSpecV1SourceDtoToJSON,
} from './ActivityLogSpecV1SourceDto';

/**
 * 
 * @export
 * @interface ActivityLogSpecV1Dto
 */
export interface ActivityLogSpecV1Dto {
    /**
     * code of activity log title (translation should be done by API client)
     * @type {string}
     * @memberof ActivityLogSpecV1Dto
     */
    type: ActivityLogSpecV1DtoTypeEnum;
    /**
     * code of activity log description (translation should be done by API client with the use of values from activity log Properties)
     * @type {string}
     * @memberof ActivityLogSpecV1Dto
     */
    description?: string;
    /**
     * additional properties of an activity log. Should be base for translations and building context.
     * @type {{ [key: string]: string | undefined; }}
     * @memberof ActivityLogSpecV1Dto
     */
    properties?: { [key: string]: string | undefined; };
    /**
     * 
     * @type {ActivityLogSpecV1SourceDto}
     * @memberof ActivityLogSpecV1Dto
     */
    source: ActivityLogSpecV1SourceDto;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogSpecV1Dto
     */
    level: ActivityLogSpecV1DtoLevelEnum;
    /**
     * service to which activity log is related
     * @type {string}
     * @memberof ActivityLogSpecV1Dto
     */
    service?: string;
    /**
     * revision to which activity log is related
     * @type {string}
     * @memberof ActivityLogSpecV1Dto
     */
    revision?: string;
    /**
     * instance to which activity log is related
     * @type {string}
     * @memberof ActivityLogSpecV1Dto
     */
    instance?: string;
    /**
     * frequency of activity logs
     * @type {number}
     * @memberof ActivityLogSpecV1Dto
     */
    frequency?: number;
}


/**
 * @export
 */
export const ActivityLogSpecV1DtoTypeEnum = {
    ApplicationCreated: 'APPLICATION_CREATED',
    BlankApplicationCreationTriggered: 'BLANK_APPLICATION_CREATION_TRIGGERED',
    ApplicationQuickstartTriggered: 'APPLICATION_QUICKSTART_TRIGGERED',
    BlankServiceCreated: 'BLANK_SERVICE_CREATED',
    ServiceQuickstartTriggered: 'SERVICE_QUICKSTART_TRIGGERED',
    ServiceCopied: 'SERVICE_COPIED',
    ServiceUpdateTriggered: 'SERVICE_UPDATE_TRIGGERED',
    ServiceCreationTriggered: 'SERVICE_CREATION_TRIGGERED',
    ServiceUpdated: 'SERVICE_UPDATED',
    ServicePatched: 'SERVICE_PATCHED',
    ServiceDeleted: 'SERVICE_DELETED',
    ServiceSaved: 'SERVICE_SAVED',
    ServiceDeploymentTriggered: 'SERVICE_DEPLOYMENT_TRIGGERED',
    RevisionEnabled: 'REVISION_ENABLED',
    RevisionDisabled: 'REVISION_DISABLED',
    RevisionCreated: 'REVISION_CREATED',
    RevisionUpdated: 'REVISION_UPDATED',
    RevisionDeleted: 'REVISION_DELETED',
    RevisionInstanceDeleted: 'REVISION_INSTANCE_DELETED',
    PipelineStarted: 'PIPELINE_STARTED',
    PipelineCompleted: 'PIPELINE_COMPLETED',
    PipelineFailed: 'PIPELINE_FAILED',
    InstanceAdded: 'INSTANCE_ADDED',
    InstanceRunning: 'INSTANCE_RUNNING',
    InstanceModified: 'INSTANCE_MODIFIED',
    InstanceUnhealthy: 'INSTANCE_UNHEALTHY',
    InstanceWarning: 'INSTANCE_WARNING',
    InstanceDeleted: 'INSTANCE_DELETED',
    InstanceError: 'INSTANCE_ERROR',
    InstanceBookmark: 'INSTANCE_BOOKMARK',
    HarborProjectCreated: 'HARBOR_PROJECT_CREATED',
    GithubRepositoryCreated: 'GITHUB_REPOSITORY_CREATED',
    GithubCiPipelineCompleted: 'GITHUB_CI_PIPELINE_COMPLETED',
    DatabaseCreationTriggered: 'DATABASE_CREATION_TRIGGERED',
    DatabaseCreated: 'DATABASE_CREATED',
    DatabaseCreationFailed: 'DATABASE_CREATION_FAILED',
    DatabaseUpdationTriggered: 'DATABASE_UPDATION_TRIGGERED',
    DatabaseUpdated: 'DATABASE_UPDATED',
    DatabaseUpdationFailed: 'DATABASE_UPDATION_FAILED',
    DatabaseDeletionTriggered: 'DATABASE_DELETION_TRIGGERED',
    DatabaseDeleted: 'DATABASE_DELETED',
    DatabaseDeletionFailed: 'DATABASE_DELETION_FAILED',
    RetinaAccountCreationTriggered: 'RETINA_ACCOUNT_CREATION_TRIGGERED',
    RetinaAccountUpdateTriggered: 'RETINA_ACCOUNT_UPDATE_TRIGGERED',
    RetinaAccountDeletionTriggered: 'RETINA_ACCOUNT_DELETION_TRIGGERED',
    RetinaSchemaCreationTriggered: 'RETINA_SCHEMA_CREATION_TRIGGERED',
    RetinaSchemaUpdateTriggered: 'RETINA_SCHEMA_UPDATE_TRIGGERED',
    RetinaSchemaDeletionTriggered: 'RETINA_SCHEMA_DELETION_TRIGGERED',
    RetinaTopicCreationTriggered: 'RETINA_TOPIC_CREATION_TRIGGERED',
    RetinaTopicUpdateTriggered: 'RETINA_TOPIC_UPDATE_TRIGGERED',
    RetinaTopicDeletionTriggered: 'RETINA_TOPIC_DELETION_TRIGGERED'
} as const;
export type ActivityLogSpecV1DtoTypeEnum = typeof ActivityLogSpecV1DtoTypeEnum[keyof typeof ActivityLogSpecV1DtoTypeEnum];

/**
 * @export
 */
export const ActivityLogSpecV1DtoLevelEnum = {
    Fatal: 'FATAL',
    Error: 'ERROR',
    Warn: 'WARN',
    Info: 'INFO'
} as const;
export type ActivityLogSpecV1DtoLevelEnum = typeof ActivityLogSpecV1DtoLevelEnum[keyof typeof ActivityLogSpecV1DtoLevelEnum];


/**
 * Check if a given object implements the ActivityLogSpecV1Dto interface.
 */
export function instanceOfActivityLogSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "level" in value;

    return isInstance;
}

export function ActivityLogSpecV1DtoFromJSON(json: any): ActivityLogSpecV1Dto {
    return ActivityLogSpecV1DtoFromJSONTyped(json, false);
}

export function ActivityLogSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityLogSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'source': ActivityLogSpecV1SourceDtoFromJSON(json['source']),
        'level': json['level'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'revision': !exists(json, 'revision') ? undefined : json['revision'],
        'instance': !exists(json, 'instance') ? undefined : json['instance'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
    };
}

export function ActivityLogSpecV1DtoToJSON(value?: ActivityLogSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'description': value.description,
        'properties': value.properties,
        'source': ActivityLogSpecV1SourceDtoToJSON(value.source),
        'level': value.level,
        'service': value.service,
        'revision': value.revision,
        'instance': value.instance,
        'frequency': value.frequency,
    };
}

