/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalTrafficTypeV1Dto } from './ExternalTrafficTypeV1Dto';
import {
    ExternalTrafficTypeV1DtoFromJSON,
    ExternalTrafficTypeV1DtoFromJSONTyped,
    ExternalTrafficTypeV1DtoToJSON,
} from './ExternalTrafficTypeV1Dto';

/**
 * 
 * @export
 * @interface NoTrafficSpecV1Dto
 */
export interface NoTrafficSpecV1Dto {
    /**
     * 
     * @type {ExternalTrafficTypeV1Dto}
     * @memberof NoTrafficSpecV1Dto
     */
    externalTrafficType: ExternalTrafficTypeV1Dto;
}

/**
 * Check if a given object implements the NoTrafficSpecV1Dto interface.
 */
export function instanceOfNoTrafficSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalTrafficType" in value;

    return isInstance;
}

export function NoTrafficSpecV1DtoFromJSON(json: any): NoTrafficSpecV1Dto {
    return NoTrafficSpecV1DtoFromJSONTyped(json, false);
}

export function NoTrafficSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoTrafficSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalTrafficType': ExternalTrafficTypeV1DtoFromJSON(json['externalTrafficType']),
    };
}

export function NoTrafficSpecV1DtoToJSON(value?: NoTrafficSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalTrafficType': ExternalTrafficTypeV1DtoToJSON(value.externalTrafficType),
    };
}

