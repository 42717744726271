/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of schema for the topic. At the moment we are not supporting PROTOBUF.
 * 
 * @export
 */
export const SchemaTypeV1Dto = {
    Json: 'JSON',
    Avro: 'AVRO'
} as const;
export type SchemaTypeV1Dto = typeof SchemaTypeV1Dto[keyof typeof SchemaTypeV1Dto];


export function SchemaTypeV1DtoFromJSON(json: any): SchemaTypeV1Dto {
    return SchemaTypeV1DtoFromJSONTyped(json, false);
}

export function SchemaTypeV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaTypeV1Dto {
    return json as SchemaTypeV1Dto;
}

export function SchemaTypeV1DtoToJSON(value?: SchemaTypeV1Dto | null): any {
    return value as any;
}

