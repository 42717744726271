/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiProxyTrafficSpecV1ConfigDto } from './ApiProxyTrafficSpecV1ConfigDto';
import {
    ApiProxyTrafficSpecV1ConfigDtoFromJSON,
    ApiProxyTrafficSpecV1ConfigDtoFromJSONTyped,
    ApiProxyTrafficSpecV1ConfigDtoToJSON,
} from './ApiProxyTrafficSpecV1ConfigDto';
import type { ExternalTrafficTypeV1Dto } from './ExternalTrafficTypeV1Dto';
import {
    ExternalTrafficTypeV1DtoFromJSON,
    ExternalTrafficTypeV1DtoFromJSONTyped,
    ExternalTrafficTypeV1DtoToJSON,
} from './ExternalTrafficTypeV1Dto';

/**
 * 
 * @export
 * @interface ApiProxyTrafficSpecV1Dto
 */
export interface ApiProxyTrafficSpecV1Dto {
    /**
     * 
     * @type {ExternalTrafficTypeV1Dto}
     * @memberof ApiProxyTrafficSpecV1Dto
     */
    externalTrafficType: ExternalTrafficTypeV1Dto;
    /**
     * 
     * @type {string}
     * @memberof ApiProxyTrafficSpecV1Dto
     */
    resourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProxyTrafficSpecV1Dto
     */
    revisionId?: string;
    /**
     * `LOCAL` Interface internal to the Application, is not supposed to be used outside of the application boundary. Used for the application services integrations which do not cross the application domain boundary. 
     * `EXPERIENCE` Interface internal to the Application, is not supposed to be used outside of the application boundary. Used by the application to drive Frontend user experience (BFF). 
     * `TECHNOLOGY` Interface published by a Technology platform (e.g. Grafana API) which is intended for the enterprise level consumption by the engineering and product teams. 
     * `PARTNER` Customer facing integration interfaces, not intended for consumption by Maersk internal business applications. 
     * `SHARED` Integration interfaces for the cross-domain integration within Maersk.
     * 
     * @type {string}
     * @memberof ApiProxyTrafficSpecV1Dto
     */
    proxyClass?: ApiProxyTrafficSpecV1DtoProxyClassEnum;
    /**
     * Domain of the API Proxy
     * @type {string}
     * @memberof ApiProxyTrafficSpecV1Dto
     */
    domain?: ApiProxyTrafficSpecV1DtoDomainEnum;
    /**
     * Base path of the API Proxy
     * @type {string}
     * @memberof ApiProxyTrafficSpecV1Dto
     */
    basePath?: string;
    /**
     * 
     * @type {ApiProxyTrafficSpecV1ConfigDto}
     * @memberof ApiProxyTrafficSpecV1Dto
     */
    config?: ApiProxyTrafficSpecV1ConfigDto;
}


/**
 * @export
 */
export const ApiProxyTrafficSpecV1DtoProxyClassEnum = {
    Local: 'LOCAL'
} as const;
export type ApiProxyTrafficSpecV1DtoProxyClassEnum = typeof ApiProxyTrafficSpecV1DtoProxyClassEnum[keyof typeof ApiProxyTrafficSpecV1DtoProxyClassEnum];

/**
 * @export
 */
export const ApiProxyTrafficSpecV1DtoDomainEnum = {
    MaerskCom: 'maersk.com',
    ApmterminalsCom: 'apmterminals.com'
} as const;
export type ApiProxyTrafficSpecV1DtoDomainEnum = typeof ApiProxyTrafficSpecV1DtoDomainEnum[keyof typeof ApiProxyTrafficSpecV1DtoDomainEnum];


/**
 * Check if a given object implements the ApiProxyTrafficSpecV1Dto interface.
 */
export function instanceOfApiProxyTrafficSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalTrafficType" in value;

    return isInstance;
}

export function ApiProxyTrafficSpecV1DtoFromJSON(json: any): ApiProxyTrafficSpecV1Dto {
    return ApiProxyTrafficSpecV1DtoFromJSONTyped(json, false);
}

export function ApiProxyTrafficSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProxyTrafficSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalTrafficType': ExternalTrafficTypeV1DtoFromJSON(json['externalTrafficType']),
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
        'revisionId': !exists(json, 'revisionId') ? undefined : json['revisionId'],
        'proxyClass': !exists(json, 'proxyClass') ? undefined : json['proxyClass'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'basePath': !exists(json, 'basePath') ? undefined : json['basePath'],
        'config': !exists(json, 'config') ? undefined : ApiProxyTrafficSpecV1ConfigDtoFromJSON(json['config']),
    };
}

export function ApiProxyTrafficSpecV1DtoToJSON(value?: ApiProxyTrafficSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalTrafficType': ExternalTrafficTypeV1DtoToJSON(value.externalTrafficType),
        'resourceId': value.resourceId,
        'revisionId': value.revisionId,
        'proxyClass': value.proxyClass,
        'domain': value.domain,
        'basePath': value.basePath,
        'config': ApiProxyTrafficSpecV1ConfigDtoToJSON(value.config),
    };
}

