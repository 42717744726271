/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecretsStorageSpecV1Dto } from './SecretsStorageSpecV1Dto';
import {
    SecretsStorageSpecV1DtoFromJSON,
    SecretsStorageSpecV1DtoFromJSONTyped,
    SecretsStorageSpecV1DtoToJSON,
} from './SecretsStorageSpecV1Dto';
import type { SecretsStorageStatusV1Dto } from './SecretsStorageStatusV1Dto';
import {
    SecretsStorageStatusV1DtoFromJSON,
    SecretsStorageStatusV1DtoFromJSONTyped,
    SecretsStorageStatusV1DtoToJSON,
} from './SecretsStorageStatusV1Dto';

/**
 * 
 * @export
 * @interface SecretsStorageV1Dto
 */
export interface SecretsStorageV1Dto {
    /**
     * 
     * @type {string}
     * @memberof SecretsStorageV1Dto
     */
    revisionId: string;
    /**
     * 
     * @type {SecretsStorageSpecV1Dto}
     * @memberof SecretsStorageV1Dto
     */
    spec: SecretsStorageSpecV1Dto;
    /**
     * 
     * @type {SecretsStorageStatusV1Dto}
     * @memberof SecretsStorageV1Dto
     */
    status?: SecretsStorageStatusV1Dto;
}

/**
 * Check if a given object implements the SecretsStorageV1Dto interface.
 */
export function instanceOfSecretsStorageV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "revisionId" in value;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function SecretsStorageV1DtoFromJSON(json: any): SecretsStorageV1Dto {
    return SecretsStorageV1DtoFromJSONTyped(json, false);
}

export function SecretsStorageV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretsStorageV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revisionId': json['revisionId'],
        'spec': SecretsStorageSpecV1DtoFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : SecretsStorageStatusV1DtoFromJSON(json['status']),
    };
}

export function SecretsStorageV1DtoToJSON(value?: SecretsStorageV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revisionId': value.revisionId,
        'spec': SecretsStorageSpecV1DtoToJSON(value.spec),
        'status': SecretsStorageStatusV1DtoToJSON(value.status),
    };
}

