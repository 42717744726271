/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiProxyTrafficSpecV1ConfigDto
 */
export interface ApiProxyTrafficSpecV1ConfigDto {
    /**
     * Connection timeout in milliseconds for the for establishing a connection to the upstream server.
     * @type {number}
     * @memberof ApiProxyTrafficSpecV1ConfigDto
     */
    connectionTimeoutMs?: number;
    /**
     * Read timeout in milliseconds.
     * @type {number}
     * @memberof ApiProxyTrafficSpecV1ConfigDto
     */
    readTimeoutMs?: number;
    /**
     * Write timeout in milliseconds.
     * @type {number}
     * @memberof ApiProxyTrafficSpecV1ConfigDto
     */
    writeTimeoutMs?: number;
    /**
     * The maximum size of the request body in megabytes.
     * @type {number}
     * @memberof ApiProxyTrafficSpecV1ConfigDto
     */
    requestSizeLimitMb?: number;
    /**
     * The maximum number of requests allowed in a minute.
     * @type {number}
     * @memberof ApiProxyTrafficSpecV1ConfigDto
     */
    spikeArrestPerMinute?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiProxyTrafficSpecV1ConfigDto
     */
    excludePathPrefixes?: Array<string>;
}

/**
 * Check if a given object implements the ApiProxyTrafficSpecV1ConfigDto interface.
 */
export function instanceOfApiProxyTrafficSpecV1ConfigDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiProxyTrafficSpecV1ConfigDtoFromJSON(json: any): ApiProxyTrafficSpecV1ConfigDto {
    return ApiProxyTrafficSpecV1ConfigDtoFromJSONTyped(json, false);
}

export function ApiProxyTrafficSpecV1ConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProxyTrafficSpecV1ConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectionTimeoutMs': !exists(json, 'connectionTimeoutMs') ? undefined : json['connectionTimeoutMs'],
        'readTimeoutMs': !exists(json, 'readTimeoutMs') ? undefined : json['readTimeoutMs'],
        'writeTimeoutMs': !exists(json, 'writeTimeoutMs') ? undefined : json['writeTimeoutMs'],
        'requestSizeLimitMb': !exists(json, 'requestSizeLimitMb') ? undefined : json['requestSizeLimitMb'],
        'spikeArrestPerMinute': !exists(json, 'spikeArrestPerMinute') ? undefined : json['spikeArrestPerMinute'],
        'excludePathPrefixes': !exists(json, 'excludePathPrefixes') ? undefined : json['excludePathPrefixes'],
    };
}

export function ApiProxyTrafficSpecV1ConfigDtoToJSON(value?: ApiProxyTrafficSpecV1ConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectionTimeoutMs': value.connectionTimeoutMs,
        'readTimeoutMs': value.readTimeoutMs,
        'writeTimeoutMs': value.writeTimeoutMs,
        'requestSizeLimitMb': value.requestSizeLimitMb,
        'spikeArrestPerMinute': value.spikeArrestPerMinute,
        'excludePathPrefixes': value.excludePathPrefixes,
    };
}

