/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnvironmentClassV1Dto } from './EnvironmentClassV1Dto';
import {
    EnvironmentClassV1DtoFromJSON,
    EnvironmentClassV1DtoFromJSONTyped,
    EnvironmentClassV1DtoToJSON,
} from './EnvironmentClassV1Dto';
import type { PipelineV1Dto } from './PipelineV1Dto';
import {
    PipelineV1DtoFromJSON,
    PipelineV1DtoFromJSONTyped,
    PipelineV1DtoToJSON,
} from './PipelineV1Dto';
import type { ResourceRefV1Dto } from './ResourceRefV1Dto';
import {
    ResourceRefV1DtoFromJSON,
    ResourceRefV1DtoFromJSONTyped,
    ResourceRefV1DtoToJSON,
} from './ResourceRefV1Dto';
import type { TagV1Dto } from './TagV1Dto';
import {
    TagV1DtoFromJSON,
    TagV1DtoFromJSONTyped,
    TagV1DtoToJSON,
} from './TagV1Dto';
import type { TopicV1AllOfSpecDto } from './TopicV1AllOfSpecDto';
import {
    TopicV1AllOfSpecDtoFromJSON,
    TopicV1AllOfSpecDtoFromJSONTyped,
    TopicV1AllOfSpecDtoToJSON,
} from './TopicV1AllOfSpecDto';

/**
 * 
 * @export
 * @interface TopicV1Dto
 */
export interface TopicV1Dto {
    /**
     * Retina resource ID
     * 
     * @type {string}
     * @memberof TopicV1Dto
     */
    readonly id?: string;
    /**
     * The human-readable name of the resource.
     * @type {string}
     * @memberof TopicV1Dto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TopicV1Dto
     */
    description?: string;
    /**
     * Code of application which owns this resource.
     * @type {string}
     * @memberof TopicV1Dto
     */
    readonly applicationCode: string;
    /**
     * revisionId is a unique identifier of a resource revision.
     * 
     * @type {string}
     * @memberof TopicV1Dto
     */
    revisionId?: string;
    /**
     * A list of tags identifying the set of credentials' usage context. Provides additional context to the credentials and enhances searchability.
     * 
     * @type {Array<TagV1Dto>}
     * @memberof TopicV1Dto
     */
    tags?: Array<TagV1Dto>;
    /**
     * 
     * @type {EnvironmentClassV1Dto}
     * @memberof TopicV1Dto
     */
    environmentClass?: EnvironmentClassV1Dto;
    /**
     * 
     * @type {PipelineV1Dto}
     * @memberof TopicV1Dto
     */
    pipeline?: PipelineV1Dto;
    /**
     * 
     * @type {Array<ResourceRefV1Dto>}
     * @memberof TopicV1Dto
     */
    readonly consumerAccounts?: Array<ResourceRefV1Dto>;
    /**
     * 
     * @type {Array<ResourceRefV1Dto>}
     * @memberof TopicV1Dto
     */
    readonly producerAccounts?: Array<ResourceRefV1Dto>;
    /**
     * 
     * @type {string}
     * @memberof TopicV1Dto
     */
    readonly retinaManagerLink?: string;
    /**
     * 
     * @type {TopicV1AllOfSpecDto}
     * @memberof TopicV1Dto
     */
    spec: TopicV1AllOfSpecDto;
}

/**
 * Check if a given object implements the TopicV1Dto interface.
 */
export function instanceOfTopicV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "applicationCode" in value;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function TopicV1DtoFromJSON(json: any): TopicV1Dto {
    return TopicV1DtoFromJSONTyped(json, false);
}

export function TopicV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicationCode': json['applicationCode'],
        'revisionId': !exists(json, 'revisionId') ? undefined : json['revisionId'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagV1DtoFromJSON)),
        'environmentClass': !exists(json, 'environmentClass') ? undefined : EnvironmentClassV1DtoFromJSON(json['environmentClass']),
        'pipeline': !exists(json, 'pipeline') ? undefined : PipelineV1DtoFromJSON(json['pipeline']),
        'consumerAccounts': !exists(json, 'consumerAccounts') ? undefined : ((json['consumerAccounts'] as Array<any>).map(ResourceRefV1DtoFromJSON)),
        'producerAccounts': !exists(json, 'producerAccounts') ? undefined : ((json['producerAccounts'] as Array<any>).map(ResourceRefV1DtoFromJSON)),
        'retinaManagerLink': !exists(json, 'retinaManagerLink') ? undefined : json['retinaManagerLink'],
        'spec': TopicV1AllOfSpecDtoFromJSON(json['spec']),
    };
}

export function TopicV1DtoToJSON(value?: TopicV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'revisionId': value.revisionId,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagV1DtoToJSON)),
        'environmentClass': EnvironmentClassV1DtoToJSON(value.environmentClass),
        'pipeline': PipelineV1DtoToJSON(value.pipeline),
        'spec': TopicV1AllOfSpecDtoToJSON(value.spec),
    };
}

