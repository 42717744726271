/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Defines a resource type to provision, it can be multiple resources e.g. SCHEMA or TOPIC
 * 
 * @export
 */
export const ExternalTrafficTypeV1Dto = {
    NoTraffic: 'NO_TRAFFIC',
    ApiProxy: 'API_PROXY',
    GenericProxy: 'GENERIC_PROXY'
} as const;
export type ExternalTrafficTypeV1Dto = typeof ExternalTrafficTypeV1Dto[keyof typeof ExternalTrafficTypeV1Dto];


export function ExternalTrafficTypeV1DtoFromJSON(json: any): ExternalTrafficTypeV1Dto {
    return ExternalTrafficTypeV1DtoFromJSONTyped(json, false);
}

export function ExternalTrafficTypeV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalTrafficTypeV1Dto {
    return json as ExternalTrafficTypeV1Dto;
}

export function ExternalTrafficTypeV1DtoToJSON(value?: ExternalTrafficTypeV1Dto | null): any {
    return value as any;
}

